export enum Role {
  SUPER_ADMIN = 1,
  ADMIN,
  DEAN = 3,
  DEPARTMENT_MANAGER,
  PROGRAM_MANAGER = 5,
  COORDINATOR,
  INSTRUCTOR = 7,
  STUDENT,
}

export const ROLES = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Dean',
  4: 'Department Manager',
  5: 'Program Manager',
  6: 'Coordinator',
  7: 'Instructor',
  8: 'Student',
};
